import React,  {useEffect, useRef} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {

  const emailForm = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://wondrous-designer-7266.ck.page/31342c23ba/index.js";
    script.async = true;
    script.dataset.uid = "31342c23ba";
    emailForm.current.appendChild(script);
  })

    return (
  <Layout>
    <Seo title="Product Garden" />
    <div className="pt-12 container">    
      <p>
        Hey, I'm Tom. I co-founded <a href="https://hubblehq.com">Hubble</a> and was CTO there for a little over 7 years. 
      </p>

      <p>
        This website is my product garden. The garden metaphor focuses on the process rather than the end result. 
        It's a place for me to tend to my thoughts, projects and learnings with care and craft. 
        I don't want to overly optimise, or stress about it, but rather treat it as something to enjoy and experiment with.
        This is the approach I want to take when product building too, rather than the metric-driven rush that is usually encouraged. 
        I'll probably write more about this at some point.
      </p>

      <p>
        Now that I've left Hubble, I'll be trying to figure out what I want to build next.
        Whilst I do this, I'll also be spending some time mentoring other founders and product/engineering leaders and consulting for early-stage startups. 
        I hope that in sharing my experiences, learnings and mistakes from the past 7 years, I can help others navigate their own tech and product challenges.
      </p>

      <p>
        One thing I discovered at Hubble is my love for product. Although nominally CTO I spent a lot of my time leading the product side of the business.  
        My main interest is in the intersection of product and software engineering, ensuring they work seamlessly together, 
        understanding the trade-offs and managing sometimes conflicting priorities in order to build the best outcome for both the customer and the business. 
      </p>

      <p>
        If you'd like to get in touch with me about mentoring, consulting or anything else, send me an email at <a href="mailto:tom@productgarden.io">tom@productgarden.io</a>.
      </p>
      
      <p>
        I'll also occasionally be writing about product, tech, company building or just a new project I'm working on. If that interests you at all, then you can subscribe below.
      </p>

      <div ref={emailForm}></div>

      <hr />

      <p>
        Right now this page is very basic. It'll probably stay like it! But I definitely want to add a few more sections, like:
      </p>

      <ul className="list-disc list-inside">
        <li>A personal canon (<a href="https://www.brendanschlagel.com/2017/11/05/canonize-creating-personal-canon-template/">example</a>)</li>
        <li>Now page (<a href="https://nownownow.com/about">example</a>)</li>
        <li>A blog (including why I left Hubble)</li>
        <li>Some more info on my mentoring and consulting services</li>
        <li>Projects page</li>
      </ul>
    </div>

  </Layout>
    )
}

export default IndexPage
